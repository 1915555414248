// Step 1: Import React
import * as React from 'react'
import { Link } from 'gatsby'
import Layout2 from '../components/layout2'
import { StaticImage } from 'gatsby-plugin-image'
import Seo2 from '../components/seo2'
import Footer from '../components/footer'

// Step 2: Define your component
const IndexPage = () => {
  return (
    <Layout2 pageTitle="Welcome!">
      <p>We want to deliver to you a fun and comical twist to tech reviews. Yes, we all have fun, we say funny things, yada yada. We can do that, we are human too. We're going to deliver more though... We're going to rate a J score on the products and give you the good, the bad, and the ugly with these products. Enjoy!</p>
    <StaticImage
        alt="A picture of Jeff in front of ruins at Chichen Itza"
        src="../images/homejeff.png"
      />    
      <br></br>
      <br></br>
      </Layout2>
  )
}

// You'll learn about this in the next task, just copy it for now
export const Head = () => <Seo2 title="Home" />

// Step 3: Export your component
export default IndexPage